module.exports = {
  '2A864886F70D': 'rsadsi',
  '2A864886F70D01': 'pkcs',
  '2A864886F70D0202': 'md2',
  '2A864886F70D0205': 'md5',
  '2A864886F70D0304': 'rc4',
  '2A864886F70D010101': 'rsaEncryption',
  '2A864886F70D010102': 'md2WithRSAEncryption',
  '2A864886F70D010104': 'md5WithRSAEncryption',
  '2A864886F70D010501': 'pbeWithMD2AndDES_CBC',
  '2A864886F70D010503': 'pbeWithMD5AndDES_CBC',
  '55': 'X500',
  '5504': 'X509',
  '550403': 'commonName',
  '550406': 'countryName',
  '550407': 'localityName',
  '550408': 'stateOrProvinceName',
  '55040A': 'organizationName',
  '55040B': 'organizationalUnitName',
  '55080101': 'rsa',
  '2A864886F70D0107': 'pkcs7',
  '2A864886F70D010701': 'pkcs7_data',
  '2A864886F70D010702': 'pkcs7_signed',
  '2A864886F70D010703': 'pkcs7_enveloped',
  '2A864886F70D010704': 'pkcs7_signedAndEnveloped',
  '2A864886F70D010705': 'pkcs7_digest',
  '2A864886F70D010706': 'pkcs7_encrypted',
  '2A864886F70D0103': 'pkcs3',
  '2A864886F70D010301': 'dhKeyAgreement',
  '2B0E030206': 'des_ecb',
  '2B0E030209': 'des_cfb64',
  '2B0E030207': 'des_cbc',
  '2B0E030211': 'des_ede_ecb',
  '2B06010401813C07010102': 'idea_cbc',
  '2A864886F70D0302': 'rc2_cbc',
  '2B0E030212': 'sha',
  '2B0E03020F': 'shaWithRSAEncryption',
  '2A864886F70D0307': 'des_ede3_cbc',
  '2B0E030208': 'des_ofb64',
  '2A864886F70D0109': 'pkcs9',
  '2A864886F70D010901': 'pkcs9_emailAddress',
  '2A864886F70D010902': 'pkcs9_unstructuredName',
  '2A864886F70D010903': 'pkcs9_contentType',
  '2A864886F70D010904': 'pkcs9_messageDigest',
  '2A864886F70D010905': 'pkcs9_signingTime',
  '2A864886F70D010906': 'pkcs9_countersignature',
  '2A864886F70D010907': 'pkcs9_challengePassword',
  '2A864886F70D010908': 'pkcs9_unstructuredAddress',
  '2A864886F70D010909': 'pkcs9_extCertAttributes',
  '6086480186F842': 'netscape',
  '6086480186F84201': 'netscape_cert_extension',
  '6086480186F84202': 'netscape_data_type',
  '2B0E03021A': 'sha1',
  '2A864886F70D010105': 'sha1WithRSAEncryption',
  '2B0E03020D': 'dsaWithSHA',
  '2B0E03020C': 'dsa_2',
  '2A864886F70D01050B': 'pbeWithSHA1AndRC2_CBC',
  '2A864886F70D01050C': 'id_pbkdf2',
  '2B0E03021B': 'dsaWithSHA1_2',
  '6086480186F8420101': 'netscape_cert_type',
  '6086480186F8420102': 'netscape_base_url',
  '6086480186F8420103': 'netscape_revocation_url',
  '6086480186F8420104': 'netscape_ca_revocation_url',
  '6086480186F8420107': 'netscape_renewal_url',
  '6086480186F8420108': 'netscape_ca_policy_url',
  '6086480186F842010C': 'netscape_ssl_server_name',
  '6086480186F842010D': 'netscape_comment',
  '6086480186F8420205': 'netscape_cert_sequence',
  '551D': 'id_ce',
  '551D0E': 'subject_key_identifier',
  '551D0F': 'key_usage',
  '551D10': 'private_key_usage_period',
  '551D11': 'subject_alt_name',
  '551D12': 'issuer_alt_name',
  '551D13': 'basic_constraints',
  '551D14': 'crl_number',
  '551D20': 'certificate_policies',
  '551D23': 'authority_key_identifier',
  '2B0601040197550102': 'bf_cbc',
  '55080365': 'mdc2',
  '55080364': 'mdc2WithRSA',
  '55042A': 'givenName',
  '550404': 'surname',
  '55042B': 'initials',
  '0992268993F22C64012C': 'uniqueIdentifier',
  '551D1F': 'crl_distribution_points',
  '2B0E030203': 'md5WithRSA',
  '550405': 'serialNumber',
  '55040C': 'title',
  '55040D': 'description',
  '2A864886F67D07420A': 'cast5_cbc',
  '2A864886F67D07420C': 'pbeWithMD5AndCast5_CBC',
  '2A8648CE380403': 'dsaWithSHA1',
  '2B0E03021D': 'sha1WithRSA',
  '2A8648CE380401': 'dsa',
  '2B24030201': 'ripemd160',
  '2B2403030102': 'ripemd160WithRSA',
  '2A864886F70D0308': 'rc5_cbc',
  '2A864886F70D0109100308': 'zlib_compression',
  '551D25': 'ext_key_usage',
  '2B0601050507': 'id_pkix',
  '2B060105050703': 'id_kp',
  '2B06010505070301': 'server_auth',
  '2B06010505070302': 'client_auth',
  '2B06010505070303': 'code_sign',
  '2B06010505070304': 'email_protect',
  '2B06010505070308': 'time_stamp',
  '2B060104018237020115': 'ms_code_ind',
  '2B060104018237020116': 'ms_code_com',
  '2B0601040182370A0301': 'ms_ctl_sign',
  '2B0601040182370A0303': 'ms_sgc',
  '2B0601040182370A0304': 'ms_efs',
  '6086480186F8420401': 'ns_sgc',
  '551D1B': 'delta_crl',
  '551D15': 'crl_reason',
  '551D18': 'invalidity_date',
  '2B65010401': 'sxnet',
  '2A864886F70D010C0101': 'pbe_WithSHA1And128BitRC4',
  '2A864886F70D010C0102': 'pbe_WithSHA1And40BitRC4',
  '2A864886F70D010C0103': 'pbe_WithSHA1And3_Key_TripleDES_CBC',
  '2A864886F70D010C0104': 'pbe_WithSHA1And2_Key_TripleDES_CBC',
  '2A864886F70D010C0105': 'pbe_WithSHA1And128BitRC2_CBC',
  '2A864886F70D010C0106': 'pbe_WithSHA1And40BitRC2_CBC',
  '2A864886F70D010C0A0101': 'keyBag',
  '2A864886F70D010C0A0102': 'pkcs8ShroudedKeyBag',
  '2A864886F70D010C0A0103': 'certBag',
  '2A864886F70D010C0A0104': 'crlBag',
  '2A864886F70D010C0A0105': 'secretBag',
  '2A864886F70D010C0A0106': 'safeContentsBag',
  '2A864886F70D010914': 'friendlyName',
  '2A864886F70D010915': 'localKeyID',
  '2A864886F70D01091601': 'x509Certificate',
  '2A864886F70D01091602': 'sdsiCertificate',
  '2A864886F70D01091701': 'x509Crl',
  '2A864886F70D01050D': 'pbes2',
  '2A864886F70D01050E': 'pbmac1',
  '2A864886F70D0207': 'hmacWithSHA1',
  '2B06010505070201': 'id_qt_cps',
  '2B06010505070202': 'id_qt_unotice',
  '2A864886F70D01090F': 'SMIMECapabilities',
  '2A864886F70D010504': 'pbeWithMD2AndRC2_CBC',
  '2A864886F70D010506': 'pbeWithMD5AndRC2_CBC',
  '2A864886F70D01050A': 'pbeWithSHA1AndDES_CBC',
  '2B06010401823702010E': 'ms_ext_req',
  '2A864886F70D01090E': 'ext_req',
  '550429': 'name',
  '55042E': 'dnQualifier',
  '2B060105050701': 'id_pe',
  '2B060105050730': 'id_ad',
  '2B06010505070101': 'info_access',
  '2B06010505073001': 'ad_OCSP',
  '2B06010505073002': 'ad_ca_issuers',
  '2B06010505070309': 'OCSP_sign',
  '2A': 'member_body',
  '2A8648': 'ISO_US',
  '2A8648CE38': 'X9_57',
  '2A8648CE3804': 'X9cm',
  '2A864886F70D0101': 'pkcs1',
  '2A864886F70D0105': 'pkcs5',
  '2A864886F70D010910': 'SMIME',
  '2A864886F70D01091000': 'id_smime_mod',
  '2A864886F70D01091001': 'id_smime_ct',
  '2A864886F70D01091002': 'id_smime_aa',
  '2A864886F70D01091003': 'id_smime_alg',
  '2A864886F70D01091004': 'id_smime_cd',
  '2A864886F70D01091005': 'id_smime_spq',
  '2A864886F70D01091006': 'id_smime_cti',
  '2A864886F70D0109100001': 'id_smime_mod_cms',
  '2A864886F70D0109100002': 'id_smime_mod_ess',
  '2A864886F70D0109100003': 'id_smime_mod_oid',
  '2A864886F70D0109100004': 'id_smime_mod_msg_v3',
  '2A864886F70D0109100005': 'id_smime_mod_ets_eSignature_88',
  '2A864886F70D0109100006': 'id_smime_mod_ets_eSignature_97',
  '2A864886F70D0109100007': 'id_smime_mod_ets_eSigPolicy_88',
  '2A864886F70D0109100008': 'id_smime_mod_ets_eSigPolicy_97',
  '2A864886F70D0109100101': 'id_smime_ct_receipt',
  '2A864886F70D0109100102': 'id_smime_ct_authData',
  '2A864886F70D0109100103': 'id_smime_ct_publishCert',
  '2A864886F70D0109100104': 'id_smime_ct_TSTInfo',
  '2A864886F70D0109100105': 'id_smime_ct_TDTInfo',
  '2A864886F70D0109100106': 'id_smime_ct_contentInfo',
  '2A864886F70D0109100107': 'id_smime_ct_DVCSRequestData',
  '2A864886F70D0109100108': 'id_smime_ct_DVCSResponseData',
  '2A864886F70D0109100201': 'id_smime_aa_receiptRequest',
  '2A864886F70D0109100202': 'id_smime_aa_securityLabel',
  '2A864886F70D0109100203': 'id_smime_aa_mlExpandHistory',
  '2A864886F70D0109100204': 'id_smime_aa_contentHint',
  '2A864886F70D0109100205': 'id_smime_aa_msgSigDigest',
  '2A864886F70D0109100206': 'id_smime_aa_encapContentType',
  '2A864886F70D0109100207': 'id_smime_aa_contentIdentifier',
  '2A864886F70D0109100208': 'id_smime_aa_macValue',
  '2A864886F70D0109100209': 'id_smime_aa_equivalentLabels',
  '2A864886F70D010910020A': 'id_smime_aa_contentReference',
  '2A864886F70D010910020B': 'id_smime_aa_encrypKeyPref',
  '2A864886F70D010910020C': 'id_smime_aa_signingCertificate',
  '2A864886F70D010910020D': 'id_smime_aa_smimeEncryptCerts',
  '2A864886F70D010910020E': 'id_smime_aa_timeStampToken',
  '2A864886F70D010910020F': 'id_smime_aa_ets_sigPolicyId',
  '2A864886F70D0109100210': 'id_smime_aa_ets_commitmentType',
  '2A864886F70D0109100211': 'id_smime_aa_ets_signerLocation',
  '2A864886F70D0109100212': 'id_smime_aa_ets_signerAttr',
  '2A864886F70D0109100213': 'id_smime_aa_ets_otherSigCert',
  '2A864886F70D0109100214': 'id_smime_aa_ets_contentTimestamp',
  '2A864886F70D0109100215': 'id_smime_aa_ets_CertificateRefs',
  '2A864886F70D0109100216': 'id_smime_aa_ets_RevocationRefs',
  '2A864886F70D0109100217': 'id_smime_aa_ets_certValues',
  '2A864886F70D0109100218': 'id_smime_aa_ets_revocationValues',
  '2A864886F70D0109100219': 'id_smime_aa_ets_escTimeStamp',
  '2A864886F70D010910021A': 'id_smime_aa_ets_certCRLTimestamp',
  '2A864886F70D010910021B': 'id_smime_aa_ets_archiveTimeStamp',
  '2A864886F70D010910021C': 'id_smime_aa_signatureType',
  '2A864886F70D010910021D': 'id_smime_aa_dvcs_dvc',
  '2A864886F70D0109100301': 'id_smime_alg_ESDHwith3DES',
  '2A864886F70D0109100302': 'id_smime_alg_ESDHwithRC2',
  '2A864886F70D0109100303': 'id_smime_alg_3DESwrap',
  '2A864886F70D0109100304': 'id_smime_alg_RC2wrap',
  '2A864886F70D0109100305': 'id_smime_alg_ESDH',
  '2A864886F70D0109100306': 'id_smime_alg_CMS3DESwrap',
  '2A864886F70D0109100307': 'id_smime_alg_CMSRC2wrap',
  '2A864886F70D0109100401': 'id_smime_cd_ldap',
  '2A864886F70D0109100501': 'id_smime_spq_ets_sqt_uri',
  '2A864886F70D0109100502': 'id_smime_spq_ets_sqt_unotice',
  '2A864886F70D0109100601': 'id_smime_cti_ets_proofOfOrigin',
  '2A864886F70D0109100602': 'id_smime_cti_ets_proofOfReceipt',
  '2A864886F70D0109100603': 'id_smime_cti_ets_proofOfDelivery',
  '2A864886F70D0109100604': 'id_smime_cti_ets_proofOfSender',
  '2A864886F70D0109100605': 'id_smime_cti_ets_proofOfApproval',
  '2A864886F70D0109100606': 'id_smime_cti_ets_proofOfCreation',
  '2A864886F70D0204': 'md4',
  '2B060105050700': 'id_pkix_mod',
  '2B060105050702': 'id_qt',
  '2B060105050704': 'id_it',
  '2B060105050705': 'id_pkip',
  '2B060105050706': 'id_alg',
  '2B060105050707': 'id_cmc',
  '2B060105050708': 'id_on',
  '2B060105050709': 'id_pda',
  '2B06010505070A': 'id_aca',
  '2B06010505070B': 'id_qcs',
  '2B06010505070C': 'id_cct',
  '2B06010505070001': 'id_pkix1_explicit_88',
  '2B06010505070002': 'id_pkix1_implicit_88',
  '2B06010505070003': 'id_pkix1_explicit_93',
  '2B06010505070004': 'id_pkix1_implicit_93',
  '2B06010505070005': 'id_mod_crmf',
  '2B06010505070006': 'id_mod_cmc',
  '2B06010505070007': 'id_mod_kea_profile_88',
  '2B06010505070008': 'id_mod_kea_profile_93',
  '2B06010505070009': 'id_mod_cmp',
  '2B0601050507000A': 'id_mod_qualified_cert_88',
  '2B0601050507000B': 'id_mod_qualified_cert_93',
  '2B0601050507000C': 'id_mod_attribute_cert',
  '2B0601050507000D': 'id_mod_timestamp_protocol',
  '2B0601050507000E': 'id_mod_ocsp',
  '2B0601050507000F': 'id_mod_dvcs',
  '2B06010505070010': 'id_mod_cmp2000',
  '2B06010505070102': 'biometricInfo',
  '2B06010505070103': 'qcStatements',
  '2B06010505070104': 'ac_auditEntity',
  '2B06010505070105': 'ac_targeting',
  '2B06010505070106': 'aaControls',
  '2B06010505070107': 'sbgp_ipAddrBlock',
  '2B06010505070108': 'sbgp_autonomousSysNum',
  '2B06010505070109': 'sbgp_routerIdentifier',
  '2B06010505070203': 'textNotice',
  '2B06010505070305': 'ipsecEndSystem',
  '2B06010505070306': 'ipsecTunnel',
  '2B06010505070307': 'ipsecUser',
  '2B0601050507030A': 'dvcs',
  '2B06010505070401': 'id_it_caProtEncCert',
  '2B06010505070402': 'id_it_signKeyPairTypes',
  '2B06010505070403': 'id_it_encKeyPairTypes',
  '2B06010505070404': 'id_it_preferredSymmAlg',
  '2B06010505070405': 'id_it_caKeyUpdateInfo',
  '2B06010505070406': 'id_it_currentCRL',
  '2B06010505070407': 'id_it_unsupportedOIDs',
  '2B06010505070408': 'id_it_subscriptionRequest',
  '2B06010505070409': 'id_it_subscriptionResponse',
  '2B0601050507040A': 'id_it_keyPairParamReq',
  '2B0601050507040B': 'id_it_keyPairParamRep',
  '2B0601050507040C': 'id_it_revPassphrase',
  '2B0601050507040D': 'id_it_implicitConfirm',
  '2B0601050507040E': 'id_it_confirmWaitTime',
  '2B0601050507040F': 'id_it_origPKIMessage',
  '2B06010505070501': 'id_regCtrl',
  '2B06010505070502': 'id_regInfo',
  '2B0601050507050101': 'id_regCtrl_regToken',
  '2B0601050507050102': 'id_regCtrl_authenticator',
  '2B0601050507050103': 'id_regCtrl_pkiPublicationInfo',
  '2B0601050507050104': 'id_regCtrl_pkiArchiveOptions',
  '2B0601050507050105': 'id_regCtrl_oldCertID',
  '2B0601050507050106': 'id_regCtrl_protocolEncrKey',
  '2B0601050507050201': 'id_regInfo_utf8Pairs',
  '2B0601050507050202': 'id_regInfo_certReq',
  '2B06010505070601': 'id_alg_des40',
  '2B06010505070602': 'id_alg_noSignature',
  '2B06010505070603': 'id_alg_dh_sig_hmac_sha1',
  '2B06010505070604': 'id_alg_dh_pop',
  '2B06010505070701': 'id_cmc_statusInfo',
  '2B06010505070702': 'id_cmc_identification',
  '2B06010505070703': 'id_cmc_identityProof',
  '2B06010505070704': 'id_cmc_dataReturn',
  '2B06010505070705': 'id_cmc_transactionId',
  '2B06010505070706': 'id_cmc_senderNonce',
  '2B06010505070707': 'id_cmc_recipientNonce',
  '2B06010505070708': 'id_cmc_addExtensions',
  '2B06010505070709': 'id_cmc_encryptedPOP',
  '2B0601050507070A': 'id_cmc_decryptedPOP',
  '2B0601050507070B': 'id_cmc_lraPOPWitness',
  '2B0601050507070F': 'id_cmc_getCert',
  '2B06010505070710': 'id_cmc_getCRL',
  '2B06010505070711': 'id_cmc_revokeRequest',
  '2B06010505070712': 'id_cmc_regInfo',
  '2B06010505070713': 'id_cmc_responseInfo',
  '2B06010505070715': 'id_cmc_queryPending',
  '2B06010505070716': 'id_cmc_popLinkRandom',
  '2B06010505070717': 'id_cmc_popLinkWitness',
  '2B06010505070718': 'id_cmc_confirmCertAcceptance',
  '2B06010505070801': 'id_on_personalData',
  '2B06010505070901': 'id_pda_dateOfBirth',
  '2B06010505070902': 'id_pda_placeOfBirth',
  '2B06010505070903': 'id_pda_gender',
  '2B06010505070904': 'id_pda_countryOfCitizenship',
  '2B06010505070905': 'id_pda_countryOfResidence',
  '2B06010505070A01': 'id_aca_authenticationInfo',
  '2B06010505070A02': 'id_aca_accessIdentity',
  '2B06010505070A03': 'id_aca_chargingIdentity',
  '2B06010505070A04': 'id_aca_group',
  '2B06010505070A05': 'id_aca_role',
  '2B06010505070B01': 'id_qcs_pkixQCSyntax_v1',
  '2B06010505070C01': 'id_cct_crs',
  '2B06010505070C02': 'id_cct_PKIData',
  '2B06010505070C03': 'id_cct_PKIResponse',
  '2B06010505073003': 'ad_timeStamping',
  '2B06010505073004': 'ad_dvcs',
  '2B0601050507300101': 'id_pkix_OCSP_basic',
  '2B0601050507300102': 'id_pkix_OCSP_Nonce',
  '2B0601050507300103': 'id_pkix_OCSP_CrlID',
  '2B0601050507300104': 'id_pkix_OCSP_acceptableResponses',
  '2B0601050507300105': 'id_pkix_OCSP_noCheck',
  '2B0601050507300106': 'id_pkix_OCSP_archiveCutoff',
  '2B0601050507300107': 'id_pkix_OCSP_serviceLocator',
  '2B0601050507300108': 'id_pkix_OCSP_extendedStatus',
  '2B0601050507300109': 'id_pkix_OCSP_valid',
  '2B060105050730010A': 'id_pkix_OCSP_path',
  '2B060105050730010B': 'id_pkix_OCSP_trustRoot',
  '2B0E0302': 'algorithm',
  '2B0E03020B': 'rsaSignature',
  '5508': 'X500algorithms',
  '2B06': 'dod',
  '2B0601': 'iana',
  '2B060101': 'Directory',
  '2B060102': 'Management',
  '2B060103': 'Experimental',
  '2B060104': 'Private',
  '2B060105': 'Security',
  '2B060106': 'SNMPv2',
  '2B060107': 'Mail',
  '2B06010401': 'Enterprises',
  '2B060104018B3A8258': 'dcObject',
  '0992268993F22C640119': 'domainComponent',
  '0992268993F22C64040D': 'Domain',
  '550105': 'selected_attribute_types',
  '55010537': 'clearance',
  '2A864886F70D010103': 'md4WithRSAEncryption',
  '2B0601050507010A': 'ac_proxying',
  '2B0601050507010B': 'sinfo_access',
  '2B06010505070A06': 'id_aca_encAttrs',
  '550448': 'role',
  '551D24': 'policy_constraints',
  '551D37': 'target_information',
  '551D38': 'no_rev_avail',
  '2A8648CE3D': 'ansi_X9_62',
  '2A8648CE3D0101': 'X9_62_prime_field',
  '2A8648CE3D0102': 'X9_62_characteristic_two_field',
  '2A8648CE3D0201': 'X9_62_id_ecPublicKey',
  '2A8648CE3D030101': 'X9_62_prime192v1',
  '2A8648CE3D030102': 'X9_62_prime192v2',
  '2A8648CE3D030103': 'X9_62_prime192v3',
  '2A8648CE3D030104': 'X9_62_prime239v1',
  '2A8648CE3D030105': 'X9_62_prime239v2',
  '2A8648CE3D030106': 'X9_62_prime239v3',
  '2A8648CE3D030107': 'X9_62_prime256v1',
  '2A8648CE3D0401': 'ecdsa_with_SHA1',
  '2B0601040182371101': 'ms_csp_name',
  '608648016503040101': 'aes_128_ecb',
  '608648016503040102': 'aes_128_cbc',
  '608648016503040103': 'aes_128_ofb128',
  '608648016503040104': 'aes_128_cfb128',
  '608648016503040115': 'aes_192_ecb',
  '608648016503040116': 'aes_192_cbc',
  '608648016503040117': 'aes_192_ofb128',
  '608648016503040118': 'aes_192_cfb128',
  '608648016503040129': 'aes_256_ecb',
  '60864801650304012A': 'aes_256_cbc',
  '60864801650304012B': 'aes_256_ofb128',
  '60864801650304012C': 'aes_256_cfb128',
  '551D17': 'hold_instruction_code',
  '2A8648CE380201': 'hold_instruction_none',
  '2A8648CE380202': 'hold_instruction_call_issuer',
  '2A8648CE380203': 'hold_instruction_reject',
  '09': 'data',
  '099226': 'pss',
  '0992268993F22C': 'ucl',
  '0992268993F22C64': 'pilot',
  '0992268993F22C6401': 'pilotAttributeType',
  '0992268993F22C6403': 'pilotAttributeSyntax',
  '0992268993F22C6404': 'pilotObjectClass',
  '0992268993F22C640A': 'pilotGroups',
  '0992268993F22C640304': 'iA5StringSyntax',
  '0992268993F22C640305': 'caseIgnoreIA5StringSyntax',
  '0992268993F22C640403': 'pilotObject',
  '0992268993F22C640404': 'pilotPerson',
  '0992268993F22C640405': 'account',
  '0992268993F22C640406': 'document',
  '0992268993F22C640407': 'room',
  '0992268993F22C640409': 'documentSeries',
  '0992268993F22C64040E': 'rFC822localPart',
  '0992268993F22C64040F': 'dNSDomain',
  '0992268993F22C640411': 'domainRelatedObject',
  '0992268993F22C640412': 'friendlyCountry',
  '0992268993F22C640413': 'simpleSecurityObject',
  '0992268993F22C640414': 'pilotOrganization',
  '0992268993F22C640415': 'pilotDSA',
  '0992268993F22C640416': 'qualityLabelledData',
  '0992268993F22C640101': 'userId',
  '0992268993F22C640102': 'textEncodedORAddress',
  '0992268993F22C640103': 'rfc822Mailbox',
  '0992268993F22C640104': 'info',
  '0992268993F22C640105': 'favouriteDrink',
  '0992268993F22C640106': 'roomNumber',
  '0992268993F22C640107': 'photo',
  '0992268993F22C640108': 'userClass',
  '0992268993F22C640109': 'host',
  '0992268993F22C64010A': 'manager',
  '0992268993F22C64010B': 'documentIdentifier',
  '0992268993F22C64010C': 'documentTitle',
  '0992268993F22C64010D': 'documentVersion',
  '0992268993F22C64010E': 'documentAuthor',
  '0992268993F22C64010F': 'documentLocation',
  '0992268993F22C640114': 'homeTelephoneNumber',
  '0992268993F22C640115': 'secretary',
  '0992268993F22C640116': 'otherMailbox',
  '0992268993F22C640117': 'lastModifiedTime',
  '0992268993F22C640118': 'lastModifiedBy',
  '0992268993F22C64011A': 'aRecord',
  '0992268993F22C64011B': 'pilotAttributeType27',
  '0992268993F22C64011C': 'mXRecord',
  '0992268993F22C64011D': 'nSRecord',
  '0992268993F22C64011E': 'sOARecord',
  '0992268993F22C64011F': 'cNAMERecord',
  '0992268993F22C640125': 'associatedDomain',
  '0992268993F22C640126': 'associatedName',
  '0992268993F22C640127': 'homePostalAddress',
  '0992268993F22C640128': 'personalTitle',
  '0992268993F22C640129': 'mobileTelephoneNumber',
  '0992268993F22C64012A': 'pagerTelephoneNumber',
  '0992268993F22C64012B': 'friendlyCountryName',
  '0992268993F22C64012D': 'organizationalStatus',
  '0992268993F22C64012E': 'janetMailbox',
  '0992268993F22C64012F': 'mailPreferenceOption',
  '0992268993F22C640130': 'buildingName',
  '0992268993F22C640131': 'dSAQuality',
  '0992268993F22C640132': 'singleLevelQuality',
  '0992268993F22C640133': 'subtreeMinimumQuality',
  '0992268993F22C640134': 'subtreeMaximumQuality',
  '0992268993F22C640135': 'personalSignature',
  '0992268993F22C640136': 'dITRedirect',
  '0992268993F22C640137': 'audio',
  '0992268993F22C640138': 'documentPublisher',
  '55042D': 'x500UniqueIdentifier',
  '2B06010701': 'mime_mhs',
  '2B0601070101': 'mime_mhs_headings',
  '2B0601070102': 'mime_mhs_bodies',
  '2B060107010101': 'id_hex_partial_message',
  '2B060107010102': 'id_hex_multipart_message',
  '55042C': 'generationQualifier',
  '550441': 'pseudonym',
  '672A': 'id_set',
  '672A00': 'set_ctype',
  '672A01': 'set_msgExt',
  '672A03': 'set_attr',
  '672A05': 'set_policy',
  '672A07': 'set_certExt',
  '672A08': 'set_brand',
  '672A0000': 'setct_PANData',
  '672A0001': 'setct_PANToken',
  '672A0002': 'setct_PANOnly',
  '672A0003': 'setct_OIData',
  '672A0004': 'setct_PI',
  '672A0005': 'setct_PIData',
  '672A0006': 'setct_PIDataUnsigned',
  '672A0007': 'setct_HODInput',
  '672A0008': 'setct_AuthResBaggage',
  '672A0009': 'setct_AuthRevReqBaggage',
  '672A000A': 'setct_AuthRevResBaggage',
  '672A000B': 'setct_CapTokenSeq',
  '672A000C': 'setct_PInitResData',
  '672A000D': 'setct_PI_TBS',
  '672A000E': 'setct_PResData',
  '672A0010': 'setct_AuthReqTBS',
  '672A0011': 'setct_AuthResTBS',
  '672A0012': 'setct_AuthResTBSX',
  '672A0013': 'setct_AuthTokenTBS',
  '672A0014': 'setct_CapTokenData',
  '672A0015': 'setct_CapTokenTBS',
  '672A0016': 'setct_AcqCardCodeMsg',
  '672A0017': 'setct_AuthRevReqTBS',
  '672A0018': 'setct_AuthRevResData',
  '672A0019': 'setct_AuthRevResTBS',
  '672A001A': 'setct_CapReqTBS',
  '672A001B': 'setct_CapReqTBSX',
  '672A001C': 'setct_CapResData',
  '672A001D': 'setct_CapRevReqTBS',
  '672A001E': 'setct_CapRevReqTBSX',
  '672A001F': 'setct_CapRevResData',
  '672A0020': 'setct_CredReqTBS',
  '672A0021': 'setct_CredReqTBSX',
  '672A0022': 'setct_CredResData',
  '672A0023': 'setct_CredRevReqTBS',
  '672A0024': 'setct_CredRevReqTBSX',
  '672A0025': 'setct_CredRevResData',
  '672A0026': 'setct_PCertReqData',
  '672A0027': 'setct_PCertResTBS',
  '672A0028': 'setct_BatchAdminReqData',
  '672A0029': 'setct_BatchAdminResData',
  '672A002A': 'setct_CardCInitResTBS',
  '672A002B': 'setct_MeAqCInitResTBS',
  '672A002C': 'setct_RegFormResTBS',
  '672A002D': 'setct_CertReqData',
  '672A002E': 'setct_CertReqTBS',
  '672A002F': 'setct_CertResData',
  '672A0030': 'setct_CertInqReqTBS',
  '672A0031': 'setct_ErrorTBS',
  '672A0032': 'setct_PIDualSignedTBE',
  '672A0033': 'setct_PIUnsignedTBE',
  '672A0034': 'setct_AuthReqTBE',
  '672A0035': 'setct_AuthResTBE',
  '672A0036': 'setct_AuthResTBEX',
  '672A0037': 'setct_AuthTokenTBE',
  '672A0038': 'setct_CapTokenTBE',
  '672A0039': 'setct_CapTokenTBEX',
  '672A003A': 'setct_AcqCardCodeMsgTBE',
  '672A003B': 'setct_AuthRevReqTBE',
  '672A003C': 'setct_AuthRevResTBE',
  '672A003D': 'setct_AuthRevResTBEB',
  '672A003E': 'setct_CapReqTBE',
  '672A003F': 'setct_CapReqTBEX',
  '672A0040': 'setct_CapResTBE',
  '672A0041': 'setct_CapRevReqTBE',
  '672A0042': 'setct_CapRevReqTBEX',
  '672A0043': 'setct_CapRevResTBE',
  '672A0044': 'setct_CredReqTBE',
  '672A0045': 'setct_CredReqTBEX',
  '672A0046': 'setct_CredResTBE',
  '672A0047': 'setct_CredRevReqTBE',
  '672A0048': 'setct_CredRevReqTBEX',
  '672A0049': 'setct_CredRevResTBE',
  '672A004A': 'setct_BatchAdminReqTBE',
  '672A004B': 'setct_BatchAdminResTBE',
  '672A004C': 'setct_RegFormReqTBE',
  '672A004D': 'setct_CertReqTBE',
  '672A004E': 'setct_CertReqTBEX',
  '672A004F': 'setct_CertResTBE',
  '672A0050': 'setct_CRLNotificationTBS',
  '672A0051': 'setct_CRLNotificationResTBS',
  '672A0052': 'setct_BCIDistributionTBS',
  '672A0101': 'setext_genCrypt',
  '672A0103': 'setext_miAuth',
  '672A0104': 'setext_pinSecure',
  '672A0105': 'setext_pinAny',
  '672A0107': 'setext_track2',
  '672A0108': 'setext_cv',
  '672A0500': 'set_policy_root',
  '672A0700': 'setCext_hashedRoot',
  '672A0701': 'setCext_certType',
  '672A0702': 'setCext_merchData',
  '672A0703': 'setCext_cCertRequired',
  '672A0704': 'setCext_tunneling',
  '672A0705': 'setCext_setExt',
  '672A0706': 'setCext_setQualf',
  '672A0707': 'setCext_PGWYcapabilities',
  '672A0708': 'setCext_TokenIdentifier',
  '672A0709': 'setCext_Track2Data',
  '672A070A': 'setCext_TokenType',
  '672A070B': 'setCext_IssuerCapabilities',
  '672A0300': 'setAttr_Cert',
  '672A0301': 'setAttr_PGWYcap',
  '672A0302': 'setAttr_TokenType',
  '672A0303': 'setAttr_IssCap',
  '672A030000': 'set_rootKeyThumb',
  '672A030001': 'set_addPolicy',
  '672A030201': 'setAttr_Token_EMV',
  '672A030202': 'setAttr_Token_B0Prime',
  '672A030303': 'setAttr_IssCap_CVM',
  '672A030304': 'setAttr_IssCap_T2',
  '672A030305': 'setAttr_IssCap_Sig',
  '672A03030301': 'setAttr_GenCryptgrm',
  '672A03030401': 'setAttr_T2Enc',
  '672A03030402': 'setAttr_T2cleartxt',
  '672A03030501': 'setAttr_TokICCsig',
  '672A03030502': 'setAttr_SecDevSig',
  '672A0801': 'set_brand_IATA_ATA',
  '672A081E': 'set_brand_Diners',
  '672A0822': 'set_brand_AmericanExpress',
  '672A0823': 'set_brand_JCB',
  '672A0804': 'set_brand_Visa',
  '672A0805': 'set_brand_MasterCard',
  '672A08AE7B': 'set_brand_Novus',
  '2A864886F70D030A': 'des_cdmf',
  '2A864886F70D010106': 'rsaOAEPEncryptionSET',
  '67': 'international_organizations',
  '2B060104018237140202': 'ms_smartcard_login',
  '2B060104018237140203': 'ms_upn',
  '550409': 'streetAddress',
  '550411': 'postalCode',
  '2B060105050715': 'id_ppl',
  '2B0601050507010E': 'proxyCertInfo',
  '2B06010505071500': 'id_ppl_anyLanguage',
  '2B06010505071501': 'id_ppl_inheritAll',
  '551D1E': 'name_constraints',
  '2B06010505071502': 'Independent',
  '2A864886F70D01010B': 'sha256WithRSAEncryption',
  '2A864886F70D01010C': 'sha384WithRSAEncryption',
  '2A864886F70D01010D': 'sha512WithRSAEncryption',
  '2A864886F70D01010E': 'sha224WithRSAEncryption',
  '608648016503040201': 'sha256',
  '608648016503040202': 'sha384',
  '608648016503040203': 'sha512',
  '608648016503040204': 'sha224',
  '2B': 'identified_organization',
  '2B8104': 'certicom_arc',
  '672B': 'wap',
  '672B01': 'wap_wsg',
  '2A8648CE3D010203': 'X9_62_id_characteristic_two_basis',
  '2A8648CE3D01020301': 'X9_62_onBasis',
  '2A8648CE3D01020302': 'X9_62_tpBasis',
  '2A8648CE3D01020303': 'X9_62_ppBasis',
  '2A8648CE3D030001': 'X9_62_c2pnb163v1',
  '2A8648CE3D030002': 'X9_62_c2pnb163v2',
  '2A8648CE3D030003': 'X9_62_c2pnb163v3',
  '2A8648CE3D030004': 'X9_62_c2pnb176v1',
  '2A8648CE3D030005': 'X9_62_c2tnb191v1',
  '2A8648CE3D030006': 'X9_62_c2tnb191v2',
  '2A8648CE3D030007': 'X9_62_c2tnb191v3',
  '2A8648CE3D030008': 'X9_62_c2onb191v4',
  '2A8648CE3D030009': 'X9_62_c2onb191v5',
  '2A8648CE3D03000A': 'X9_62_c2pnb208w1',
  '2A8648CE3D03000B': 'X9_62_c2tnb239v1',
  '2A8648CE3D03000C': 'X9_62_c2tnb239v2',
  '2A8648CE3D03000D': 'X9_62_c2tnb239v3',
  '2A8648CE3D03000E': 'X9_62_c2onb239v4',
  '2A8648CE3D03000F': 'X9_62_c2onb239v5',
  '2A8648CE3D030010': 'X9_62_c2pnb272w1',
  '2A8648CE3D030011': 'X9_62_c2pnb304w1',
  '2A8648CE3D030012': 'X9_62_c2tnb359v1',
  '2A8648CE3D030013': 'X9_62_c2pnb368w1',
  '2A8648CE3D030014': 'X9_62_c2tnb431r1',
  '2B81040006': 'secp112r1',
  '2B81040007': 'secp112r2',
  '2B8104001C': 'secp128r1',
  '2B8104001D': 'secp128r2',
  '2B81040009': 'secp160k1',
  '2B81040008': 'secp160r1',
  '2B8104001E': 'secp160r2',
  '2B8104001F': 'secp192k1',
  '2B81040020': 'secp224k1',
  '2B81040021': 'secp224r1',
  '2B8104000A': 'secp256k1',
  '2B81040022': 'secp384r1',
  '2B81040023': 'secp521r1',
  '2B81040004': 'sect113r1',
  '2B81040005': 'sect113r2',
  '2B81040016': 'sect131r1',
  '2B81040017': 'sect131r2',
  '2B81040001': 'sect163k1',
  '2B81040002': 'sect163r1',
  '2B8104000F': 'sect163r2',
  '2B81040018': 'sect193r1',
  '2B81040019': 'sect193r2',
  '2B8104001A': 'sect233k1',
  '2B8104001B': 'sect233r1',
  '2B81040003': 'sect239k1',
  '2B81040010': 'sect283k1',
  '2B81040011': 'sect283r1',
  '2B81040024': 'sect409k1',
  '2B81040025': 'sect409r1',
  '2B81040026': 'sect571k1',
  '2B81040027': 'sect571r1',
  '672B010401': 'wap_wsg_idm_ecid_wtls1',
  '672B010403': 'wap_wsg_idm_ecid_wtls3',
  '672B010404': 'wap_wsg_idm_ecid_wtls4',
  '672B010405': 'wap_wsg_idm_ecid_wtls5',
  '672B010406': 'wap_wsg_idm_ecid_wtls6',
  '672B010407': 'wap_wsg_idm_ecid_wtls7',
  '672B010408': 'wap_wsg_idm_ecid_wtls8',
  '672B010409': 'wap_wsg_idm_ecid_wtls9',
  '672B01040A': 'wap_wsg_idm_ecid_wtls10',
  '672B01040B': 'wap_wsg_idm_ecid_wtls11',
  '672B01040C': 'wap_wsg_idm_ecid_wtls12',
  '551D2000': 'any_policy',
  '551D21': 'policy_mappings',
  '551D36': 'inhibit_any_policy',
  '2A83088C9A4B3D01010102': 'camellia_128_cbc',
  '2A83088C9A4B3D01010103': 'camellia_192_cbc',
  '2A83088C9A4B3D01010104': 'camellia_256_cbc',
  '03A2310503010901': 'camellia_128_ecb',
  '03A2310503010915': 'camellia_192_ecb',
  '03A2310503010929': 'camellia_256_ecb',
  '03A2310503010904': 'camellia_128_cfb128',
  '03A2310503010918': 'camellia_192_cfb128',
  '03A231050301092C': 'camellia_256_cfb128',
  '03A2310503010903': 'camellia_128_ofb128',
  '03A2310503010917': 'camellia_192_ofb128',
  '03A231050301092B': 'camellia_256_ofb128',
  '551D09': 'subject_directory_attributes',
  '551D1C': 'issuing_distribution_point',
  '551D1D': 'certificate_issuer',
  '2A831A8C9A44': 'kisa',
  '2A831A8C9A440103': 'seed_ecb',
  '2A831A8C9A440104': 'seed_cbc',
  '2A831A8C9A440106': 'seed_ofb128',
  '2A831A8C9A440105': 'seed_cfb128',
  '2B06010505080101': 'hmac_md5',
  '2B06010505080102': 'hmac_sha1',
  '2A864886F67D07420D': 'id_PasswordBasedMAC',
  '2A864886F67D07421E': 'id_DHBasedMac',
  '2B06010505070410': 'id_it_suppLangTags',
  '2B06010505073005': 'caRepository',
  '2A864886F70D0109100109': 'id_smime_ct_compressedData',
  '2A864886F70D010910011B': 'id_ct_asciiTextWithCRLF',
  '608648016503040105': 'id_aes128_wrap',
  '608648016503040119': 'id_aes192_wrap',
  '60864801650304012D': 'id_aes256_wrap',
  '2A8648CE3D0402': 'ecdsa_with_Recommended',
  '2A8648CE3D0403': 'ecdsa_with_Specified',
  '2A8648CE3D040301': 'ecdsa_with_SHA224',
  '2A8648CE3D040302': 'ecdsa_with_SHA256',
  '2A8648CE3D040303': 'ecdsa_with_SHA384',
  '2A8648CE3D040304': 'ecdsa_with_SHA512',
  '2A864886F70D0206': 'hmacWithMD5',
  '2A864886F70D0208': 'hmacWithSHA224',
  '2A864886F70D0209': 'hmacWithSHA256',
  '2A864886F70D020A': 'hmacWithSHA384',
  '2A864886F70D020B': 'hmacWithSHA512',
  '608648016503040301': 'dsa_with_SHA224',
  '608648016503040302': 'dsa_with_SHA256',
  '28CF06030037': 'whirlpool',
  '2A85030202': 'cryptopro',
  '2A85030209': 'cryptocom',
  '2A8503020203': 'id_GostR3411_94_with_GostR3410_2001',
  '2A8503020204': 'id_GostR3411_94_with_GostR3410_94',
  '2A8503020209': 'id_GostR3411_94',
  '2A850302020A': 'id_HMACGostR3411_94',
  '2A8503020213': 'id_GostR3410_2001',
  '2A8503020214': 'id_GostR3410_94',
  '2A8503020215': 'id_Gost28147_89',
  '2A8503020216': 'id_Gost28147_89_MAC',
  '2A8503020217': 'id_GostR3411_94_prf',
  '2A8503020262': 'id_GostR3410_2001DH',
  '2A8503020263': 'id_GostR3410_94DH',
  '2A850302020E01': 'id_Gost28147_89_CryptoPro_KeyMeshing',
  '2A850302020E00': 'id_Gost28147_89_None_KeyMeshing',
  '2A850302021E00': 'id_GostR3411_94_TestParamSet',
  '2A850302021E01': 'id_GostR3411_94_CryptoProParamSet',
  '2A850302021F00': 'id_Gost28147_89_TestParamSet',
  '2A850302021F01': 'id_Gost28147_89_CryptoPro_A_ParamSet',
  '2A850302021F02': 'id_Gost28147_89_CryptoPro_B_ParamSet',
  '2A850302021F03': 'id_Gost28147_89_CryptoPro_C_ParamSet',
  '2A850302021F04': 'id_Gost28147_89_CryptoPro_D_ParamSet',
  '2A850302021F05': 'id_Gost28147_89_CryptoPro_Oscar_1_1_ParamSet',
  '2A850302021F06': 'id_Gost28147_89_CryptoPro_Oscar_1_0_ParamSet',
  '2A850302021F07': 'id_Gost28147_89_CryptoPro_RIC_1_ParamSet',
  '2A850302022000': 'id_GostR3410_94_TestParamSet',
  '2A850302022002': 'id_GostR3410_94_CryptoPro_A_ParamSet',
  '2A850302022003': 'id_GostR3410_94_CryptoPro_B_ParamSet',
  '2A850302022004': 'id_GostR3410_94_CryptoPro_C_ParamSet',
  '2A850302022005': 'id_GostR3410_94_CryptoPro_D_ParamSet',
  '2A850302022101': 'id_GostR3410_94_CryptoPro_XchA_ParamSet',
  '2A850302022102': 'id_GostR3410_94_CryptoPro_XchB_ParamSet',
  '2A850302022103': 'id_GostR3410_94_CryptoPro_XchC_ParamSet',
  '2A850302022300': 'id_GostR3410_2001_TestParamSet',
  '2A850302022301': 'id_GostR3410_2001_CryptoPro_A_ParamSet',
  '2A850302022302': 'id_GostR3410_2001_CryptoPro_B_ParamSet',
  '2A850302022303': 'id_GostR3410_2001_CryptoPro_C_ParamSet',
  '2A850302022400': 'id_GostR3410_2001_CryptoPro_XchA_ParamSet',
  '2A850302022401': 'id_GostR3410_2001_CryptoPro_XchB_ParamSet',
  '2A850302021401': 'id_GostR3410_94_a',
  '2A850302021402': 'id_GostR3410_94_aBis',
  '2A850302021403': 'id_GostR3410_94_b',
  '2A850302021404': 'id_GostR3410_94_bBis',
  '2A85030209010601': 'id_Gost28147_89_cc',
  '2A85030209010503': 'id_GostR3410_94_cc',
  '2A85030209010504': 'id_GostR3410_2001_cc',
  '2A85030209010303': 'id_GostR3411_94_with_GostR3410_94_cc',
  '2A85030209010304': 'id_GostR3411_94_with_GostR3410_2001_cc',
  '2A85030209010801': 'id_GostR3410_2001_ParamSet_cc',
  '2B0601040182371102': 'LocalKeySet',
  '551D2E': 'freshest_crl',
  '2B06010505070803': 'id_on_permanentIdentifier',
  '55040E': 'searchGuide',
  '55040F': 'businessCategory',
  '550410': 'postalAddress',
  '550412': 'postOfficeBox',
  '550413': 'physicalDeliveryOfficeName',
  '550414': 'telephoneNumber',
  '550415': 'telexNumber',
  '550416': 'teletexTerminalIdentifier',
  '550417': 'facsimileTelephoneNumber',
  '550418': 'x121Address',
  '550419': 'internationaliSDNNumber',
  '55041A': 'registeredAddress',
  '55041B': 'destinationIndicator',
  '55041C': 'preferredDeliveryMethod',
  '55041D': 'presentationAddress',
  '55041E': 'supportedApplicationContext',
  '55041F': 'member',
  '550420': 'owner',
  '550421': 'roleOccupant',
  '550422': 'seeAlso',
  '550423': 'userPassword',
  '550424': 'userCertificate',
  '550425': 'cACertificate',
  '550426': 'authorityRevocationList',
  '550427': 'certificateRevocationList',
  '550428': 'crossCertificatePair',
  '55042F': 'enhancedSearchGuide',
  '550430': 'protocolInformation',
  '550431': 'distinguishedName',
  '550432': 'uniqueMember',
  '550433': 'houseIdentifier',
  '550434': 'supportedAlgorithms',
  '550435': 'deltaRevocationList',
  '550436': 'dmdName',
  '2A864886F70D0109100309': 'id_alg_PWRI_KEK',
  '608648016503040106': 'aes_128_gcm',
  '608648016503040107': 'aes_128_ccm',
  '608648016503040108': 'id_aes128_wrap_pad',
  '60864801650304011A': 'aes_192_gcm',
  '60864801650304011B': 'aes_192_ccm',
  '60864801650304011C': 'id_aes192_wrap_pad',
  '60864801650304012E': 'aes_256_gcm',
  '60864801650304012F': 'aes_256_ccm',
  '608648016503040130': 'id_aes256_wrap_pad',
  '2A83088C9A4B3D01010302': 'id_camellia128_wrap',
  '2A83088C9A4B3D01010303': 'id_camellia192_wrap',
  '2A83088C9A4B3D01010304': 'id_camellia256_wrap',
  '551D2500': 'anyExtendedKeyUsage',
  '2A864886F70D010108': 'mgf1',
  '2A864886F70D01010A': 'rsassaPss',
  '2A864886F70D010107': 'rsaesOaep',
  '2A8648CE3E0201': 'dhpublicnumber',
  '2B2403030208010101': 'brainpoolP160r1',
  '2B2403030208010102': 'brainpoolP160t1',
  '2B2403030208010103': 'brainpoolP192r1',
  '2B2403030208010104': 'brainpoolP192t1',
  '2B2403030208010105': 'brainpoolP224r1',
  '2B2403030208010106': 'brainpoolP224t1',
  '2B2403030208010107': 'brainpoolP256r1',
  '2B2403030208010108': 'brainpoolP256t1',
  '2B2403030208010109': 'brainpoolP320r1',
  '2B240303020801010A': 'brainpoolP320t1',
  '2B240303020801010B': 'brainpoolP384r1',
  '2B240303020801010C': 'brainpoolP384t1',
  '2B240303020801010D': 'brainpoolP512r1',
  '2B240303020801010E': 'brainpoolP512t1',
  '2A864886F70D010109': 'pSpecified',
  '2B81051086483F0002': 'dhSinglePass_stdDH_sha1kdf_scheme',
  '2B8104010B00': 'dhSinglePass_stdDH_sha224kdf_scheme',
  '2B8104010B01': 'dhSinglePass_stdDH_sha256kdf_scheme',
  '2B8104010B02': 'dhSinglePass_stdDH_sha384kdf_scheme',
  '2B8104010B03': 'dhSinglePass_stdDH_sha512kdf_scheme',
  '2B81051086483F0003': 'dhSinglePass_cofactorDH_sha1kdf_scheme',
  '2B8104010E00': 'dhSinglePass_cofactorDH_sha224kdf_scheme',
  '2B8104010E01': 'dhSinglePass_cofactorDH_sha256kdf_scheme',
  '2B8104010E02': 'dhSinglePass_cofactorDH_sha384kdf_scheme',
  '2B8104010E03': 'dhSinglePass_cofactorDH_sha512kdf_scheme',
  '2B06010401D679020402': 'ct_precert_scts',
  '2B06010401D679020403': 'ct_precert_poison',
  '2B06010401D679020404': 'ct_precert_signer',
  '2B06010401D679020405': 'ct_cert_scts',
  '2B0601040182373C020101': 'jurisdictionLocalityName',
  '2B0601040182373C020102': 'jurisdictionStateOrProvinceName',
  '2B0601040182373C020103': 'jurisdictionCountryName',
  '03A2310503010906': 'camellia_128_gcm',
  '03A2310503010907': 'camellia_128_ccm',
  '03A2310503010909': 'camellia_128_ctr',
  '03A231050301090A': 'camellia_128_cmac',
  '03A231050301091A': 'camellia_192_gcm',
  '03A231050301091B': 'camellia_192_ccm',
  '03A231050301091D': 'camellia_192_ctr',
  '03A231050301091E': 'camellia_192_cmac',
  '03A231050301092E': 'camellia_256_gcm',
  '03A231050301092F': 'camellia_256_ccm',
  '03A2310503010931': 'camellia_256_ctr',
  '03A2310503010932': 'camellia_256_cmac',
  '2B06010401DA47040B': 'id_scrypt',
  '2A85030701': 'id_tc26',
  '2A8503070101': 'id_tc26_algorithms',
  '2A850307010101': 'id_tc26_sign',
  '2A85030701010101': 'id_GostR3410_2012_256',
  '2A85030701010102': 'id_GostR3410_2012_512',
  '2A850307010102': 'id_tc26_digest',
  '2A85030701010202': 'id_GostR3411_2012_256',
  '2A85030701010203': 'id_GostR3411_2012_512',
  '2A850307010103': 'id_tc26_signwithdigest',
  '2A85030701010302': 'id_tc26_signwithdigest_gost3410_2012_256',
  '2A85030701010303': 'id_tc26_signwithdigest_gost3410_2012_512',
  '2A850307010104': 'id_tc26_mac',
  '2A85030701010401': 'id_tc26_hmac_gost_3411_2012_256',
  '2A85030701010402': 'id_tc26_hmac_gost_3411_2012_512',
  '2A850307010105': 'id_tc26_cipher',
  '2A850307010106': 'id_tc26_agreement',
  '2A85030701010601': 'id_tc26_agreement_gost_3410_2012_256',
  '2A85030701010602': 'id_tc26_agreement_gost_3410_2012_512',
  '2A8503070102': 'id_tc26_constants',
  '2A850307010201': 'id_tc26_sign_constants',
  '2A85030701020102': 'id_tc26_gost_3410_2012_512_constants',
  '2A8503070102010200': 'id_tc26_gost_3410_2012_512_paramSetTest',
  '2A8503070102010201': 'id_tc26_gost_3410_2012_512_paramSetA',
  '2A8503070102010202': 'id_tc26_gost_3410_2012_512_paramSetB',
  '2A850307010202': 'id_tc26_digest_constants',
  '2A850307010205': 'id_tc26_cipher_constants',
  '2A85030701020501': 'id_tc26_gost_28147_constants',
  '2A8503070102050101': 'id_tc26_gost_28147_param_Z',
  '2A85030381030101': 'INN',
  '2A85036401': 'OGRN',
  '2A85036403': 'SNILS',
  '2A8503646F': 'subjectSignTool',
  '2A85036470': 'issuerSignTool',
  '2B06010505070118': 'tlsfeature',
  '2B06010505070311': 'ipsec_IKE',
  '2B06010505070312': 'capwapAC',
  '2B06010505070313': 'capwapWTP',
  '2B06010505070315': 'sshClient',
  '2B06010505070316': 'sshServer',
  '2B06010505070317': 'sendRouter',
  '2B06010505070318': 'sendProxiedRouter',
  '2B06010505070319': 'sendOwner',
  '2B0601050507031A': 'sendProxiedOwner',
  '2B0601050203': 'id_pkinit',
  '2B060105020304': 'pkInitClientAuth',
  '2B060105020305': 'pkInitKDC',
  '2B656E': 'X25519',
  '2B656F': 'X448',
  '2B060104018D3A0C020110': 'blake2b512',
  '2B060104018D3A0C020208': 'blake2s256',
  '2A864886F70D0109100113': 'id_smime_ct_contentCollection',
  '2A864886F70D0109100117': 'id_smime_ct_authEnvelopedData',
  '2A864886F70D010910011C': 'id_ct_xml',
  '2A831A8C9A6E010101': 'aria_128_ecb',
  '2A831A8C9A6E010102': 'aria_128_cbc',
  '2A831A8C9A6E010103': 'aria_128_cfb128',
  '2A831A8C9A6E010104': 'aria_128_ofb128',
  '2A831A8C9A6E010105': 'aria_128_ctr',
  '2A831A8C9A6E010106': 'aria_192_ecb',
  '2A831A8C9A6E010107': 'aria_192_cbc',
  '2A831A8C9A6E010108': 'aria_192_cfb128',
  '2A831A8C9A6E010109': 'aria_192_ofb128',
  '2A831A8C9A6E01010A': 'aria_192_ctr',
  '2A831A8C9A6E01010B': 'aria_256_ecb',
  '2A831A8C9A6E01010C': 'aria_256_cbc',
  '2A831A8C9A6E01010D': 'aria_256_cfb128',
  '2A831A8C9A6E01010E': 'aria_256_ofb128',
  '2A831A8C9A6E01010F': 'aria_256_ctr',
  '2A864886F70D010910022F': 'id_smime_aa_signingCertificateV2',
  '2B6570': 'ED25519',
  '2B6571': 'ED448',
  '550461': 'organizationIdentifier',
  '550462': 'countryCode3c',
  '550463': 'countryCode3n',
  '550464': 'dnsName',
  '2B24080303': 'x509ExtAdmission',
  '608648016503040205': 'sha512_224',
  '608648016503040206': 'sha512_256',
  '608648016503040207': 'sha3_224',
  '608648016503040208': 'sha3_256',
  '608648016503040209': 'sha3_384',
  '60864801650304020A': 'sha3_512',
  '60864801650304020B': 'shake128',
  '60864801650304020C': 'shake256',
  '60864801650304020D': 'hmac_sha3_224',
  '60864801650304020E': 'hmac_sha3_256',
  '60864801650304020F': 'hmac_sha3_384',
  '608648016503040210': 'hmac_sha3_512',
  '608648016503040303': 'dsa_with_SHA384',
  '608648016503040304': 'dsa_with_SHA512',
  '608648016503040305': 'dsa_with_SHA3_224',
  '608648016503040306': 'dsa_with_SHA3_256',
  '608648016503040307': 'dsa_with_SHA3_384',
  '608648016503040308': 'dsa_with_SHA3_512',
  '608648016503040309': 'ecdsa_with_SHA3_224',
  '60864801650304030A': 'ecdsa_with_SHA3_256',
  '60864801650304030B': 'ecdsa_with_SHA3_384',
  '60864801650304030C': 'ecdsa_with_SHA3_512',
  '60864801650304030D': 'RSA_SHA3_224',
  '60864801650304030E': 'RSA_SHA3_256',
  '60864801650304030F': 'RSA_SHA3_384',
  '608648016503040310': 'RSA_SHA3_512',
  '2A831A8C9A6E010125': 'aria_128_ccm',
  '2A831A8C9A6E010126': 'aria_192_ccm',
  '2A831A8C9A6E010127': 'aria_256_ccm',
  '2A831A8C9A6E010122': 'aria_128_gcm',
  '2A831A8C9A6E010123': 'aria_192_gcm',
  '2A831A8C9A6E010124': 'aria_256_gcm',
  '2B0601050507031B': 'cmcCA',
  '2B0601050507031C': 'cmcRA',
  '2A811CCF55016801': 'sm4_ecb',
  '2A811CCF55016802': 'sm4_cbc',
  '2A811CCF55016803': 'sm4_ofb128',
  '2A811CCF55016805': 'sm4_cfb1',
  '2A811CCF55016804': 'sm4_cfb128',
  '2A811CCF55016806': 'sm4_cfb8',
  '2A811CCF55016807': 'sm4_ctr',
  '2A811C': 'ISO_CN',
  '2A811CCF55': 'oscca',
  '2A811CCF5501': 'sm_scheme',
  '2A811CCF55018311': 'sm3',
  '2A811CCF55018378': 'sm3WithRSAEncryption',
  '2A864886F70D01010F': 'sha512_224WithRSAEncryption',
  '2A864886F70D010110': 'sha512_256WithRSAEncryption',
  '2A85030701020101': 'id_tc26_gost_3410_2012_256_constants',
  '2A8503070102010101': 'id_tc26_gost_3410_2012_256_paramSetA',
  '2A8503070102010203': 'id_tc26_gost_3410_2012_512_paramSetC'
}

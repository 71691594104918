<template>
  <div>
    <header>
      <div class="header-box">
        <svg-icon class="logo" icon-class="zxlogo" />
        <div>在线解锁</div>
      </div>
    </header>
    <div class="main-box">
      <div class="steps">
        <template v-for="(item,index) in steps">
          <div :key="index" v-if="index>0" class="line"></div>
          <div :key="index+5" class="step" :class="{'active':(index+1)===step}">
            <span class="n">{{index+1}}</span>
            {{item}}
          </div>
        </template>
      </div>
      <div class="title">
        {{step==1?'读取证书信息':step===2?'解锁':'解锁成功'}}
        <div class="hint" v-if="step===1||step===2">
          插入需解锁的Ukey，读取证书信息，解锁过程中请保持只插入一个Ukey
        </div>
      </div>
      <div class="search" v-if="step===1||step===2">
        <div class="label">证书序列号：</div>
        <input type="text" v-model="cer_info.serial" disabled placeholder="证书序列号">
        <button @click="connect" class="read">读取</button>
        <button v-if="step===2" @click="unLock" class="unlock-butn">解锁</button>
      </div>
      <div v-if="step===1" class="noInfo">
        <img class="icon" src="@/assets/image/onlineUnlock/unlock_noInfo.png" />
        <div>暂无证书信息，请插入需要解锁的Ukey，点击读取获取证书信息</div>
        <div>如有问题，请联系客服：400-030-2013</div>
      </div>
      <div v-if="step===2" class="cerInfo">
        <div class="nm">证书信息</div>
        <div>
          <div class="info">
            <div>序列号：</div>
            <div>{{cer_info.serial}}</div>
          </div>
          <div class="info">
            <div>机构名称/O：</div>
            <div>{{cer_info.organizationName}}</div>
          </div>
          <div class="info">
            <div>所属部门/OU：</div>
            <div>{{cer_info.organizationalUnitName}}</div>
          </div>
          <div class="info">
            <div>名称/CN：</div>
            <div>{{cer_info.commonName}}</div>
          </div>
          <div class="info">
            <div>国家/C：</div>
            <div>{{cer_info.countryName}}</div>
          </div>
          <div class="info">
            <div>省份/S：</div>
            <div>{{cer_info.stateOrProvinceName}}</div>
          </div>
          <div class="info">
            <div>城市/L：</div>
            <div>{{cer_info.localityName}}</div>
          </div>
          <div class="info">
            <div>开始时间：</div>
            <div>{{dateFormat(cer_info.notBefore)}}</div>
          </div>
          <div class="info">
            <div>结束时间：</div>
            <div>{{dateFormat(cer_info.notAfter)}}</div>
          </div>
        </div>
      </div>
      <div v-if="step===3" class="succeed">
        <img class="icon" src="@/assets/image/onlineUnlock/unlock_success.png" />
        <div class="msg">恭喜您，介质已解锁</div>
        <div class="hint-msg">
          <div>证书口令已重置为<span>11111111</span>，请尽快使用电子印章助手或豸信CA数字证书助手修改</div>
          <div>如有问题，请联系客服：400-030-2013</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { X509Certificate } from '@peculiar/x509'
// import pem from '@root/pem'
import * as tder from './tder'
import { Ukey } from './ukey'
export default {
  data() {
    return {
      Ukey: null,
      steps: ['读取证书信息', '解锁', '解锁成功'],
      step: 1,
      cer_info: {},
      bscode: '',
      loading: ''
    }
  },
  mounted() {
    this.Ukey = new Ukey(this)
  },
  methods: {
    connect() {
      this.loading = this.$loading({
        lock: true,
        text: '读取中',
        spinner: 'el-icon-loading'
      })
      this.Ukey.connect()
        .then(res => {
          let signCert = res.data.signCert
          let cert = tder.interpreter.certInterpreter(tder.parsePem(signCert))
          this.cer_info = {
            serial: cert.serial,
            ...cert.subject,
            ...cert.dueTime
          }
          this.loading.close()
        })
        .catch(err => {
          this.loading.close()
          this.$message.error(err.message)
        })
    },

    dateFormat(date, fmt = 'YYYY-mm-dd HH:MM:SS') {
      date = new Date(date)
      let ret
      const opt = {
        'Y+': date.getFullYear().toString(), // 年
        'm+': (date.getMonth() + 1).toString(), // 月
        'd+': date.getDate().toString(), // 日
        'H+': date.getHours().toString(), // 时
        'M+': date.getMinutes().toString(), // 分
        'S+': date.getSeconds().toString() // 秒
        // 有其他格式化字符需求可以继续添加，必须转化成字符串
      }
      for (let k in opt) {
        ret = new RegExp('(' + k + ')').exec(fmt)
        if (ret) {
          fmt = fmt.replace(
            ret[1],
            ret[1].length === 1 ? opt[k] : opt[k].padStart(ret[1].length, '0')
          )
        }
      }
      return fmt
    },
    unLock() {
      const loading = this.$loading({
        lock: true,
        text: '解锁中',
        spinner: 'el-icon-loading'
      })
      this.bscode = this.$route.query.c
      this.Ukey.unLockUkey(this.bscode).then(res => {
        loading.close()
        if (res.code === 0) {
          this.step = 3
        } else {
          this.cer_info = {}
          this.$alert(res.msg, '解锁失败', {
            confirmButtonText: '我知道了',
            type: 'warning'
          })
        }
      })
    }
  },
  watch: {
    cer_info: function(val) {
      if (val.serial) {
        this.step = 2
      } else {
        this.step = 1
      }
    }
  }
}
</script>
<style lang="scss" scoped>
header {
  text-align: center;
  border-bottom: 1px #eaeaea solid;
  display: block;
  width: 100%;
  .header-box {
    display: flex;
    align-items: center;
    font-size: 16px;
    color: #333333;
    height: 60px;
    max-width: 1200px;
    padding: 0 10px;
    margin: 0 auto;
    .logo {
      padding-right: 24px;
      border-right: 1px #dfdfe2 solid;
      width: 122px;
      height: 26px;
      color: #3a6afe;
      margin-right: 24px;
    }
  }
}
.main-box {
  margin: 0 auto;
  padding: 20px;
  text-align: left;
  width: 720px;
  .steps {
    display: flex;
    align-items: center;
    font-size: 16px;
    color: #b9bbbf;
    padding: 70px 0;
    .step {
      white-space: nowrap;
      .n {
        display: inline-block;
        text-align: center;
        width: 22px;
        line-height: 22px;
        border: 1px solid #b9bbbf;
        margin-right: 8px;
        border-radius: 50%;
        vertical-align: middle;
      }
      &.active {
        font-size: 16px;
        font-family: MicrosoftYaHeiSemibold;
        color: #3a6afe;
        line-height: 21px;
        .n {
          border: 1px solid #3a6afe;
        }
      }
    }
    .line {
      width: 145px;
      height: 1px;
      background: #e2e2e2;
      margin: 0 12px;
    }
  }
  .title {
    padding: 16px 0;
    margin-bottom: 16px;
    font-size: 21px;
    font-family: MicrosoftYaHeiSemibold;
    color: #333333;
    line-height: 28px;
    letter-spacing: 1px;
    .hint {
      font-size: 16px;
      font-family: MicrosoftYaHei;
      color: #ff0000;
      line-height: 21px;
      letter-spacing: 1px;
      margin-top: 16px;
    }
  }
  .search {
    margin-bottom: 24px;
    .label {
      font-size: 14px;
      color: #444444;
      line-height: 19px;
      letter-spacing: 1px;
      margin-bottom: 12px;
    }
    input {
      width: 281px;
      height: 32px;
      background: #ffffff;
      border-radius: 4px;
      border: 1px solid #b9bbbf;
      padding: 0 12px;
    }
    button {
      width: 80px;
      height: 34px;
      background: #3a6afe;
      border-radius: 4px;
      border: 1px solid #3a6afe;
      color: #ffffff;
      margin-left: 8px;
      &.unlock-butn {
        background-color: #43454e;
        border-color: #43454e;
      }
    }
  }
  .noInfo {
    width: 100%;
    border-radius: 4px;
    border: 1px solid #e2e2e2;
    padding: 72px 0;
    text-align: center;
    line-height: 24px;
    font-size: 14px;
    color: #6d6e71;
    letter-spacing: 1px;
    .icon {
      width: 155px;
      height: 121px;
      margin: 8px auto;
    }
  }
  .cerInfo {
    display: flex;
    align-items: center;
    flex-direction: column;
    background-image: url('~@/assets/image/onlineUnlock/cerInfo_bg.png');
    width: 100%;
    height: 628px;
    box-sizing: border-box;
    background-size: 100% 100%;
    padding: 80px 70px;
    .nm {
      font-size: 16px;
      font-weight: bold;
      color: #333333;
      line-height: 21px;
      letter-spacing: 1px;
      margin-bottom: 20px;
      text-align: center;
    }
    .info {
      line-height: 19px;
      padding: 12px 0;
      font-size: 14px;
      font-family: MicrosoftYaHei;
      color: #333333;
      letter-spacing: 1px;
      display: flex;
      & > div:first-child {
        flex-shrink: 0;
        display: inline-block;
        width: 120px;
        margin-right: 8px;
        color: #90939d;
      }
    }
  }
  .succeed {
    text-align: center;
    padding: 40px 0;
    .icon {
      width: 155px;
      height: 121px;
    }
    .msg {
      font-size: 18px;
      color: #333333;
      line-height: 24px;
      letter-spacing: 1px;
      margin: 30px 0;
    }
    .hint-msg {
      height: 100px;
      background: #fafafb;
      border-radius: 4px;
      padding-top: 32px;
      padding-left: 100px;
      border: 1px solid #e2e2e2;
      font-size: 14px;
      color: #333333;
      line-height: 19px;
      letter-spacing: 1px;
      text-align: left;
      div {
        margin-bottom: 24px;
        span {
          color: #f50000;
        }
      }
    }
  }
}
</style>

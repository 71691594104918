import { UkeyMiddleware } from '@/utils/ukeyMiddleware'
import { MessageBox } from 'element-ui'
// import request from '@/utils/request'

function getErr (err) {
  return { message: err }
}
export class Ukey {
  // eslint-disable-next-line space-before-function-paren
  constructor(self) {
    this.self = self
    this.ukeyMiddleware = new UkeyMiddleware('onlineUnlock')
  }
  connect () {
    return this.ukeyMiddleware.connect().then(res => {
      if (res.code !== 0) {
        MessageBox.alert(
          '请检查电子印章助手是否正确安装！如有问题联系400-030-2013',
          '未检测到电子印章助手控件',
          {
            confirmButtonText: '去下载'
          }
        ).then(() => {
          this.self.$util.downloadById(this.self.CONFIG.SEALAPPID)
        })
        throw getErr('Ukey链接失败')
      } else {
        return this.ukeyMiddleware.handle('GetUkeyList', {})
      }
    }).then(res => {
      if (res.code === 0) {
        this.ukey_info = res.data
        return this.disconnectUkey()
      } else {
        MessageBox.alert(
          '读取证书失败，请重新插入或更换其他Ukey！',
          '未检测到Ukey',
          {
            confirmButtonText: '我知道了'
          }
        )
        throw getErr('未检测到Ukey')
      }
    }).then(() => {

      return this.ukeyMiddleware.handle('ConnectUkey', {
        ukeyName: this.ukey_info.ukeyName,
        entry: this.ukey_info.entry
      })
    }).then(res => {
      if (res.code !== 0) {
        throw getErr('U-Key连接失败，请重试！')
      }
      this.ukey_info['hDev'] = res.data.hDev
      return this.ukeyMiddleware.handle('ExportUserCert', {
        entry: this.ukey_info.entry,
        hDev: this.ukey_info.hDev
      })
    }).then(res => {
      return Promise.resolve(res)
    })
  }
  unLockUkey (bscode) {
    return this.ukeyMiddleware.handle('unLockUkey', {
      entry: this.ukey_info.entry,
      hDev: this.ukey_info.hDev,
      bscode
    })
  }
  disconnectUkey () {
    if (this.devHandle && this.devHandle !== '') {
      let devHandle = this.devHandle
      this.devHandle = ''
      return this.ukeyMiddleware.handle('DisconnectUkey', { devHandle })
    } else {
      return new Promise((resolve) => { resolve() })
    }
  }
}
